import React, { lazy, Suspense } from 'react'
import { hot } from 'react-hot-loader/root'
import './App.scss'
import { optionsActions } from './store/options'
import Loader from './ui/common/Loader'

const OrderApp = lazy(() => import('./OrderApp'))
const VoucherApp = lazy(() => import('./VoucherApp'))

const App = ({ options }) => {
  optionsActions.init(options)
  return (
    <Suspense
      fallback={
        <div className={'d-flex justify-content-center h-100 w-100 align-items-center'}>
          <Loader size={8} />
        </div>
      }
    >
      {options.mode === 'ORDER' && <OrderApp />}
      {options.mode === 'VOUCHER' && <VoucherApp />}
    </Suspense>
  )
}

export default hot(App)
