import { createEvent, createStore } from 'effector'

export const optionsActions = {
  init: createEvent('init options'),
  setOption: createEvent('update option'),
  reset: createEvent('reset options'),
}

export const optionsStore = createStore(null)
  .on(optionsActions.init, (state, newState) => newState)
  .on(optionsActions.setOption, (state, payload) => ({ ...state, ...payload }))
  .reset(optionsActions.reset)
