import * as React from 'react'
// Reactstrap
import { Spinner as LibSpinner } from 'reactstrap'
// Styles
import './Spinner.scss'

class Spinner extends React.PureComponent {
  render() {
    const className = 'Spinner' + (this.props.className ? ' ' + this.props.className : '')
    return (
      <LibSpinner className={className} {...this.props}>
        {this.props.children}
      </LibSpinner>
    )
  }
}

export default Spinner
